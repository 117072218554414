import { useContext, useState } from "react";
import axios from "../services/api";
import useToken from "./useToken";
import { getListSetores, getSetor, iniListaAt, ordemSac } from "../utils/sac";
import { formatDataUnix } from "../utils/date";
import { getSession, setSession } from "../utils/storage";
import { iniAtendimento } from "../utils/constructor";
import { GeralContext } from "../context";
import { verificarRota } from "../utils/geral";
import useConfig from "./useConfig";

const useSac = () => {
  const { token } = useToken();
  const [listSac, setListSac] = useState(iniListaAt);
  const [sac, setSac] = useState(iniAtendimento);
  const [listOutros, setListOutros] = useState([]);
  const [listUltimos, setListUltimos] = useState([]);
  const [listFinalizados, setListFinalizados] = useState([]);
  const [loading, setLoading] = useState(true);
  const { toggleAtualizar, toggleMensagem, igFila } = useContext(GeralContext);
  const [msgExterno, setMsgExterno] = useState({ protocolo: undefined, info: '' });

  var config = {
    headers: {
      authorization: token
    }
  };

  // LISTA DE atendimento *************************************************************************************************
  const getListSac = async (params) => {
    let url = params && params.valor !== "" ? `&filtro=${params.filtro ? params.filtro.toLowerCase() : ''}&pesquisa=${params.valor}` : '';
    let setor = ''
    if (window.location.pathname === '/testcomplete')
      setor = 'TC';
    else
      setor = params && params.valor !== "" ? getListSetores() : getSetor();

    if (window.location.pathname === '/sac' || window.location.pathname === '/testcomplete') {
      try {
        const response = await axios.get(`/atendimentos?setor=${setor}${url}`, config);
        setListSac(ordemSac(response.data.result));
        toggleAtualizar(false);
      } catch (error) {
        if (error.response.data === 'Unauthorized') {
          window.location.href = '/login';
        }
      }
    }
  }

  // SAC *************************************************************************************************
  const getSac = async (id) => {
    if (id > 0) {
      const response = await axios.get(`/atendimentos/${id}`, config);
      setSac(response.data.result[0]);
    }
    setLoading(false);
  }

  // DATA *************************************************************************************************
  const getData = async () => {
    try {
      const response = await axios.get('/atendimentos/data', config);
      if (getSession('atualizar') !== response.data.result) {
        setSession('atualizar', response.data.result)
        toggleAtualizar(true);
      }
    } catch (error) {
      if (error.response.data === 'Unauthorized') {
        window.location.href = '/login';
      }
    }
  }

  // FINALIZADOS *************************************************************************************************
  const getFinalizados = (parameters) => {
    let data1 = formatDataUnix(parameters.datai);
    let data2 = formatDataUnix(parameters.dataf);

    let data = {
      datai: data1,
      dataf: data2,
      tpData: parameters.tpData,
    };

    if (parameters.setor) {
      data.setor = parameters.setor;
    }
    if (parameters.atendente) {
      data.atendente = parameters.atendente.codigo;
    }

    if (parameters.assunto) {
      data.assunto = parameters.assunto;
    }
    if (parameters.pessoa) {
      data.cliente = parameters.pessoa.codigo;
    }
    if (parameters.grupo) {
      data.grupo = 1
    }

    if (parameters.versao !== '') {
      data.versao = parameters.versao
    }
    if (parameters.pesquisa) {
      data.filtro = parameters.filtro.toLowerCase();
      data.pesquisa = parameters.pesquisa
    }

    var config = {
      method: 'get',
      url: '/atendimentos/finalizados',
      headers: {
        authorization: token
      },
      params: data
    };
    axios(config)
      .then(function (response) {
        setListFinalizados(response.data.result)
      })
      .catch(function (error) {
        console.log('Erro! Tente Novamente /n' + error);
      });

  }

  // PUTSAC *************************************************************************************************
  const putSac = async (acao, parameters) => {

    let data = '';
    switch (acao.toLowerCase()) {
      case 'atender':
        data = {fila: parameters.fila === undefined ? true : parameters.fila }
        break;
      case 'agendar':
        data = {
          "motivo": parameters.motivo,
          "datahora": formatDataUnix(parameters.datahora)
        }
        break;
      case 'anotacoes':
        acao = 'anotar';
        data = {
          "texto": parameters.texto
        }
        break;
      case 'enviar':
        data = {
          "destino": parameters.tipo
        }
        break;
      case 'retornar':
        data = {
          "assunto": parameters.assunto,
          "motivo": parameters.motivo
        }
        break;
      case 'reservar':
        data = {
          "usuario": parameters.usuario.codigo
        }
        break;
      case 'tirarreserva':
        break;
      case 'finalizar':
        data = {
          "assunto": parameters.assunto,
          "solucao": parameters.solucao,
          "direto": parameters.direto,
          "versao": parameters.versao
        }
        break;
      case 'direcionar':
        data = {
          "motivos": parameters.motivos,
          "destino": parameters.tipo,
          "software": parameters.software,
          "caminhobd": parameters.caminhobd,
          "retornado": parameters.retornado || false,
          "assunto": parameters.assunto,
          "prioridade": parameters.prioridade,
          "direcionarpara": parameters.retornado ? parameters.usuario.codigo : ''
        }
        break;
      case 'liberar':
        data = {
        }
        break;
      default:
        data = {
          "cliente": parameters.pessoa.codigo,
          "assunto": parameters.assunto,
          "prioridade": parameters.prioridade,
          "tipo": parameters.tipo,
          "contato": parameters.contato,
          "software": parameters.software,
          "caminhobd": parameters.caminhobd,
        }
        break;
    }

    await axios.put(`/atendimentos/${parameters.codigo}${acao ? '/' + acao.toLowerCase() : ''}`, data, config)
      .then(function (response) {
        if (parameters.outrosAt)
          toggleMensagem('Existem outros chamados para esta empresa!', 'warning');
        else
          toggleMensagem(response.data.result, 'success');
        if (acao)
          toggleAtualizar(true);
        else
          window.location.href = '/sac';
      })
      .catch(function (error) {
        toggleMensagem(error.response.data.error, 'error');
      })
    setSession('atualizar', 'at');
  }

  // POSTSAC
  const postSac = async (sac) => {
    const data = {
      "cliente": sac.pessoa.codigo,
      "assunto": sac.assunto,
      "prioridade": sac.prioridade,
      "contato": sac.contato,
      "tipo": sac.tipo,
      "software": sac.software,
      "conteudo": sac.conteudo,
      "origem": verificarRota('externo') ? 'E': 'I'
    }

    await axios.post('/atendimentos', data, config)
      .then(function (response) {
        if (!verificarRota('externo')) {
          toggleMensagem(`Chamado criado com sucesso! ${response.data.result}`, 'success');
          window.location.href = '/sac';
        } else {
          setMsgExterno({ protocolo: response.data.result, tipo: 'success' });
        }
      })
      .catch(function (error) {
        if (!verificarRota('externo')) {
          toggleMensagem(error.message, 'error');
        } else {
          setMsgExterno({ protocolo: '', tipo: 'error' });
        }
      })
  }

  //OUTROS *************************************************************************************************
  const getOutros = async (codpessoa) => {
    try {
      const response = await axios.get(`/atendimentos/cliente/${codpessoa}/abertos`, config);
      setListOutros(response.data.result);
    } catch (error) {
      console.log(error);
    }
  }

  // ULTIMOS *************************************************************************************************
  const getUltimos = async (parameters) => {
    let url = `/atendimentos/cliente/${parameters.codpessoa}/finalizados`;
    if (parameters.usuario) {
      url = url + `&usuario=true`;
    }
    try {
      const response = await axios.get(url, config);
      setListUltimos(response.data.result);
    } catch (error) {
      console.log(error);
    }
  }

  // VINCULAR *************************************************************************************************
  const sacVincular = async (id, master, method) => {
    let data = { 'master': master };
    let response = ''
    if (method === 'post') {
      response = await axios.post(`/atendimentos/${id}/vinculados`, data, config);
    } else {
      response = await axios.delete(`/atendimentos/${id}/vinculados/${master}`, config);
    }
    toggleMensagem(response.data.result, 'success');
    toggleAtualizar(true);
  }

  return {
    getData, putSac, postSac,
    getSac, sac,
    getListSac, listSac,
    getOutros, listOutros,
    getUltimos, listUltimos,
    getFinalizados, listFinalizados,
    sacVincular,
    loading,
    msgExterno
  };
}

export default useSac;