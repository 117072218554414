import React, { useEffect, useState } from 'react';
import { Box, Divider, Input, LinearProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import ShareIcon from '@mui/icons-material/Share';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import useAnexos from '../../../../../hooks/useAnexos';
import { StackJustify, StackLeft } from '../../../../stack';
import { ButtonFechar, ButtonLinkIcon, ButtonToolTip } from '../../../../button';
import { TitleSmall, TitleGrupos } from '../../../../typography';
import { listaLength } from '../../../../../utils/string';
import { criptografar } from '../../../../../utils/crypto';
import CachedIcon from '@mui/icons-material/Cached';
import { FileIcon, Folder, UploadIcon } from '../../../../icons';

export default function DialogAnexos(props) {
    const [selectedFile, setSelectedFile] = useState(undefined);
    const { getAnexos, postAnexos, anexos, deleteAnexos, progress, statusBar, getFile } = useAnexos();
    const hash = criptografar(props.codigo);
    const status = props.status;

    useEffect(() => {
        getAnexos(props.codigo)
    }, [progress === 100])


    useEffect(() => {
        getAnexos(props.codigo)
        setSelectedFile(undefined)
    }, [statusBar === false])

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (selectedFile) {
            postAnexos(props.codigo, selectedFile)
        }
    };

    function ListarAnexos(anexo, a) {
        return (
            <ListItem key={a}>
                <StackJustify>
                    {/* <span><ButtonIcon icon={<File fontSize='25px' />} onClick={() => getFile(props.codigo, anexo.nome)} caption={anexo.nome} color='inherit' /></span> */}
                    <span onClick={() => getFile(props.codigo, anexo.nome)} style={{ cursor: 'pointer', display: 'contents' }}>
                        <FileIcon fontSize='18px' color='iconColor' />
                        <TitleGrupos title={anexo.nome} />
                    </span>
                    <ButtonToolTip icon={<ClearIcon fontSize='25px' />} onClick={() => deleteAnexos(props.codigo, anexo.nome)} color='red' title='Excluir' />
                </StackJustify>
            </ListItem>
        );
    }

    return (
        <>
            <Divider textAlign="left" style={{ margin: '10px 0px 10px 0px' }}><TitleGrupos title="Anexos" /></Divider>
            <List >
                {listaLength(anexos) > 0 ? anexos.map(ListarAnexos) : <TitleGrupos title='Nenhum anexo encontrado.' />}
            </List>
            <Box p={1} style={{ marginLeft: '-10px ', marginRight: '10px' }}>
                {statusBar && progress < 100 ? <><LinearProgress variant="determinate" value={progress} /><div style={{ textAlign: 'center' }}><TitleGrupos title={`${progress} %`} /></div></> :
                    <Box pl={2}>
                        {!selectedFile && (
                            <StackJustify>
                                <StackLeft>
                                    {status === 'A' ? <ButtonLinkIcon target="_blank" to={`/share/${hash}`} icon={<ShareIcon />} title='Link compartilhamento' /> : ''}
                                    <ButtonToolTip onClick={() => getAnexos(props.codigo)} icon={<CachedIcon />} title='Atualizar lista' />
                                    <Input
                                        type="file"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        id="upload-file"
                                    />
                                    <label htmlFor="upload-file">
                                        <ButtonToolTip
                                            icon={<Folder />}
                                            color='orange'
                                            title='Selecione o arquivo'
                                            component="span"
                                        />
                                    </label>
                                </StackLeft>
                                {props.acao === 'Direcionar' ? '' : <ButtonFechar />}
                            </StackJustify>
                        )}
                        {selectedFile && (
                            <>
                                <Divider textAlign="left" style={{ margin: '10px 0px 10px 0px' }}><TitleGrupos title="Arquivo pendende de envio" /></Divider>
                                <StackLeft>
                                    <TitleGrupos title="Arquivo:" />
                                    {selectedFile ? <TitleSmall title={selectedFile.name} /> : ''}<br />
                                    <Box>
                                        <ButtonToolTip
                                            icon={<UploadIcon />}
                                            color='blue'
                                            title='Enviar'
                                            onClick={handleUpload}
                                        />
                                        <ButtonToolTip
                                            icon={<DeleteIcon />}
                                            color='red'
                                            title='Cancelar'
                                            onClick={() => setSelectedFile(undefined)}
                                        />
                                    </Box>
                                </StackLeft>
                            </>
                        )}
                    </Box>
                }

            </Box >
        </>
    );
};
