import * as React from 'react';
import { ButtonToolTip, LinkMenu } from "../../../button";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import GpsOffIcon from '@mui/icons-material/GpsOff';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';

import { StackJustify } from "../../../stack";
import { getPerm, permRender } from '../../../../utils/user';
import { formatDateTime } from '../../../../utils/date';
import useSac from '../../../../hooks/useSac';
import { GeralContext, SacContext } from '../../../../context';
import useRastreios from '../../../../hooks/useRastreios';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

export function MenuConteudo(props) {
    const { toggleAcao } = React.useContext(SacContext);
    const atendimento = props.atendimento;
    return (
        <span>
            {props.showDescr ? <ButtonToolTip title='Ampliar Conteúdo'
                icon={<ZoomOutMapIcon />}
                onClick={props.onClickShowConteudo}
            /> : ''}
            <ButtonToolTip title={props.ocultaConteudo ? 'Mostar todos' : 'Ocultar sem conteúdos'}
                icon={props.ocultaConteudo ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                onClick={props.onClickOcultarConteudo}
            />
            {permRender('AN',
                <ButtonToolTip title='Adicionar Anotação'
                    icon={<PostAddOutlinedIcon fontSize="small" />}
                    onClick={() => toggleAcao(atendimento.codigo, 'Anotacoes')}
                />
            )}
            {<ButtonToolTip title='Anexos'
                icon={<AttachFileIcon fontSize="small" />}
                onClick={() => toggleAcao(atendimento.codigo, 'Anexos')}
            />}
        </span>
    )
}

export function MenuItemPrincipal(props) {
    const { toggleAcao } = React.useContext(SacContext);
    const atendimento = props.atendimento;

    return (<StackJustify>
        {atendimento.status === 'A' ?
            <>
                <ButtonToolTip title='Finalizar'
                    color='red'
                    icon={<CheckOutlinedIcon />}
                    onClick={() => toggleAcao(atendimento.codigo, 'Finalizar')}
                />
                {props.showMenu ?
                    <>
                        {permRender('RE',
                            <ButtonToolTip title='Retornar'
                                icon={<SettingsBackupRestoreOutlinedIcon fontSize='small' />}
                                onClick={() => toggleAcao(atendimento.codigo, 'Retornar')}
                            />)}
                        {permRender('AG',
                            <ButtonToolTip title='Agendar'
                                icon={<AccessAlarmsOutlinedIcon fontSize='small' />}
                                onClick={() => toggleAcao(atendimento.codigo, 'Agendar')}
                            />)}
                        {permRender('DI',
                            <ButtonToolTip title='Direcionar'
                                icon={<ArrowCircleRightOutlinedIcon fontSize='small' />}
                                onClick={() => toggleAcao(atendimento.codigo, 'Direcionar')}
                            />)}
                    </>
                    : ''}
            </>
            :
            <>
                {permRender('AT',
                    <ButtonToolTip title='Atender'
                        color='green'
                        icon={<LocalPhoneOutlinedIcon />}
                        onClick={() => toggleAcao(atendimento.codigo, 'atender', atendimento.codpessoa)}
                    />)}
            </>
        }
    </StackJustify >
    )
}

export function MenuAtendimento(props) {
    const atendimento = props.atendimento;
    const { toggleAcao } = React.useContext(SacContext);
    const { putSac, sacVincular } = useSac();
    const { addRemoveRastreio } = useRastreios();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (<> {atendimento ? <>
        <ButtonToolTip
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            icon={<MoreHorizOutlinedIcon />}
            title='Menu'
        />

        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            {getPerm('ED') ? <MenuItem onClick={() => toggleAcao(atendimento.codigo, 'sac')} onClickCapture={handleClose}><CreateOutlinedIcon fontSize="small" /><LinkMenu to={`/sac/edit/${atendimento.codigo}`} caption='Editar' /></MenuItem> : ''}
            {atendimento.status === 'A' && getPerm('EV') ? <MenuItem onClick={() => toggleAcao(atendimento.codigo, 'Enviar')} onClickCapture={handleClose}><NearMeOutlinedIcon fontSize="small" /> Enviar</MenuItem> : ''}
            {atendimento.tipo !== 'AT' ? <MenuItem onClick={() => toggleAcao(atendimento.codigo, 'Marcadores')} onClickCapture={handleClose}><BookmarkBorderOutlinedIcon fontSize="small" /> Marcadores</MenuItem> : ''}
            {atendimento.tipo !== 'AT' ? <MenuItem onClick={() => toggleAcao(atendimento.codigo, 'Movimentos')} onClickCapture={handleClose}><SwipeVerticalIcon fontSize="small" /> Movimentos</MenuItem> : ''}
            {atendimento.status === 'A' ? <MenuItem onClick={() => toggleAcao(atendimento.codigo, 'Outros')} onClickCapture={handleClose}><FormatListNumberedOutlinedIcon fontSize="small" /> Outros</MenuItem> : ''}

            {atendimento.rastreio === 0 ?
                <MenuItem onClick={() => toggleAcao(atendimento.codigo, 'Rastreio')} onClickCapture={handleClose}><GpsFixedOutlinedIcon fontSize="small" /> Rastreio</MenuItem>
                :
                <MenuItem onClick={() => addRemoveRastreio(atendimento.codigo, 0, 'delete')} onClickCapture={handleClose}><GpsOffIcon fontSize="small" /> Remover rastreio</MenuItem>
            }


            {atendimento.status === 'A' ? <MenuItem onClick={() => toggleAcao(atendimento.codigo, 'Ultimos')} onClickCapture={handleClose}><LastPageOutlinedIcon fontSize="small" /> Últimos</MenuItem> : ''}
            {atendimento.vinculado ?
                <MenuItem onClick={() => sacVincular(atendimento.codigo, atendimento.vinculado, 'desvincular')} onClickCapture={handleClose}><LinkOffOutlinedIcon fontSize="small" /> Desvincular</MenuItem>
                :
                <MenuItem onClick={() => toggleAcao(atendimento.codigo, 'Vincular')} onClickCapture={handleClose}><LinkOutlinedIcon fontSize="small" /> Vincular</MenuItem>
            }
            {getPerm('RS') && atendimento.atendpref === '' && atendimento.status !== 'A' ? <MenuItem onClick={() => toggleAcao(atendimento.codigo, 'Reservar')} onClickCapture={handleClose}><PersonOutlineOutlinedIcon fontSize="small" /> Reservar</MenuItem> : ''}
            {getPerm('TR') && atendimento.atendpref !== '' && atendimento.status !== 'A' ? <MenuItem onClick={() => putSac('tirarreserva', atendimento)} onClickCapture={handleClose}><PersonRemoveOutlinedIcon fontSize="small" /> Remover Reserva</MenuItem> : ''}
            {getPerm('LI') && atendimento.status !== 'A' ? <MenuItem onClick={() => putSac('liberar', atendimento)} onClickCapture={handleClose}><ThumbUpOutlinedIcon fontSize="small" /> Liberar</MenuItem> : ''}
        </Menu>
    </>
        : ''}
    </>
    );
}

export function InfMenu(props) {
    const atendimento = props.atendimento;
    const {togglePesquisarVinc } = React.useContext(GeralContext);

    return (<StackJustify>
        {atendimento.status !== 'A' && atendimento.data_original !== '' && atendimento.usuarioag !== '' ?
            <ButtonToolTip
                title={'Agendado por: ' + atendimento.usuag + '   Data Original: ' + formatDateTime(atendimento.data_original)}
                icon={<AccessTimeOutlinedIcon fontSize='small' />}
            />
            : ''
        }
        {
            atendimento.atendpref !== '' && atendimento.status !== 'A' ?
                <ButtonToolTip
                    title={'Reservado para: ' + atendimento.atendpref}
                    icon={<PersonOutlineOutlinedIcon fontSize="small" />}
                />
                : ''
        }
        {
            atendimento.status === 'L' ?
                <ButtonToolTip
                    title='Chamado Liberado'
                    icon={<ThumbUpOutlinedIcon fontSize="small" />}
                />
                : ''
        }
        {
            atendimento.vinculado === 0 ? '' :
                <ButtonToolTip
                    title={atendimento.vinculado === atendimento.CODIGO ? 'Chamado principal ID ' + atendimento.vinculado : 'Chamado vinculado ao ID ' + atendimento.vinculado}
                    icon={<LinkOutlinedIcon fontSize="small" />}
                    onClick={() => togglePesquisarVinc(atendimento.vinculado)}
                />
        }
    </StackJustify >
    )
}