import * as React from 'react';
import useMovimento from '../../../../../hooks/useMovimentos';
import { Divider, List, ListItem, Stack } from '@mui/material';
import { ButtonFechar, ButtonIcon } from '../../../../button';
import { SacContext } from '../../../../../context';
import { TitleGrupos } from '../../../../typography';
import { getSetor } from '../../../../../utils/sac';

export default function DialogMovimentos() {
    const { listMovimento, getMovimentos, putMovimentoAt, loading } = useMovimento();
    const { toggleAcao, sac } = React.useContext(SacContext);

    React.useEffect(() => {
        getMovimentos(getSetor());
    }, [loading])

    React.useEffect(() => {
        toggleAcao(sac.codigo, 'Movimentos');
    }, [loading])

    function Movimentos(mov, m) {
        return (<ListItem key={m}>
            <ButtonIcon caption={mov.nome} color={mov.codigo === sac.movimento ? 'green' : 'red'} onClick={() => putMovimentoAt(sac.codigo, mov.codigo)} />
        </ListItem>
        );
    }

    return (<>
        <Divider textAlign="left" style={{ margin: '10px 0px 10px 0px' }}><TitleGrupos title='Movimentos' /></Divider>
        <List sx={{ background: 'inherit' }}>
            {listMovimento ? listMovimento.map(Movimentos) : ''}
        </List >
        <Stack direction='row' spacing={1} justifyContent='flex-end' paddingRight={1} paddingBottom={1}>
            <ButtonFechar />
        </Stack>
    </>);
}
