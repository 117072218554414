import React from 'react';
import { ContainerPages } from '../../components/container';
import { Box, Card, CardContent, CardMedia, Tooltip, Typography } from '@mui/material';
import { Folder } from '../../components/icons';

function Teste() {
    return (
        <ContainerPages>
            <Box p={1}>
                <Card style={{ width: '100px', height: '100px', textAlign: 'center', padding: '15px' }}>
                    <CardContent>
                        <Tooltip placement="right-start" title='SpedEFD-04695873000117-082156000-Remessa de arquivo substituto-mai2022.txt'>
                            <Folder fontSize='42px' color='orange' />
                            <Typography variant='caption'>SpedEFD-04695873000117-082156000-Remessa de arquivo substituto-mai2022.txt</Typography>
                        </Tooltip>
                    </CardContent>
                </Card>
            </Box>
        </ContainerPages>
    )
}

export default Teste;
