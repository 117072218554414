import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { getUser, permRender } from '../../utils/user';
import { ArrowLeft, BookMark, Done, GpsFixed, People, TreeBox, ListIcon, ListFolder, QuestionIcon, Storage, Lab } from '../icons';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import { GeralContext } from '../../context';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function DrawerRedsis(props) {
    const { toggleDesativarPesquisa } = React.useContext(GeralContext);
    function ListMenuItem(props) {

        return (
            <Link to={props.to} style={{ textDecoration: 'none', width: '100%', color: 'inherit' }} onClick={props.onClose} >
                <ListItem disablePadding onClick={toggleDesativarPesquisa}>
                    <ListItemButton>
                        <ListItemIcon>
                            {props.icon}
                        </ListItemIcon>
                        <ListItemText primary={props.caption} />
                    </ListItemButton>
                </ListItem>
            </Link>
        );
    }

    return (
        <Drawer
            anchor={'left'}
            open={props.state}
            onClose={props.onClose}
        >
            <DrawerHeader>
                <Toolbar style={{ textAlign: 'center' }}>
                    <Avatar alt="Logo Redsis" src="../../../app-assets/img/logo/logo.png" />
                    <Box sx={{ mr: 3 }} />
                    <IconButton onClick={props.onClose}>
                        <ArrowLeft fontSize='25px' />
                    </IconButton>
                </Toolbar>
            </DrawerHeader>
            <List style={{ width: '250px' }}>
                <ListMenuItem to={'/sac'} icon={<ListIcon />} caption='Atendimentos' />
                <ListMenuItem to={'/finalizados'} icon={<Done />} caption='Finalizados' />
                <Divider />
                <ListMenuItem to={'/faq'} icon={<QuestionIcon />} caption='FAQ' />
                <ListMenuItem to={'/ftp'} icon={<Storage />} caption='FTP' />
                <Divider />
                <ListMenuItem to={'/clientes'} icon={<People />} caption='Clientes' />
                <ListMenuItem to={'/marcadores'} icon={<BookMark />} caption='Marcadores' />
                <ListMenuItem to={'/movimentos'} icon={<SwipeVerticalIcon />} caption='Movimentos' />
                <ListMenuItem to={'/rastreios'} icon={<GpsFixed />} caption='Rastreios' />
                <ListMenuItem to={'/setores'} icon={<TreeBox />} caption='Setores' />
                {permRender('ER', <ListMenuItem to={'/versao'} icon={<ListFolder />} caption='Versionamento' />)}
                {getUser().nome === 'FILIPE' ? <ListMenuItem to={'/teste'} icon={<Lab />} caption='Teste' /> : ''}
            </List>
        </Drawer>
    );
}