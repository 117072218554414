import { useContext, useState } from "react";
import axios from "../services/api";
import useToken from "../hooks/useToken";
import { GeralContext } from "../context";

const useFTP = () => {
  const { token } = useToken();
  const [arquivos, setArquivos] = useState([]);
  const [progress, setProgress] = useState(0);
  const [statusBar, setStatusBar] = useState(false);
  const [loading, setLoading] = useState(true);
  const { toggleMensagem } = useContext(GeralContext);

  let config = {
    headers: {
      'Authorization': token,
    },
  };

  const getFTP = async (url) => {
    let iniCaminho = 'ftp/arquivos/%7Bcaminho%7D';
    let caminho = url === '' ? iniCaminho : '/ftp/arquivos/' + url.replace('/%2F', '/');
    try {
      const response = await axios.get(caminho.replaceAll('/%2F', '/'), config)
      setArquivos(response.data);
      setLoading(false);
    } catch (error) {

    }
  }

  const postArqFTP = async (caminho, arq) => {
    setStatusBar(true);
    setProgress(0);
    const formData = new FormData();
    formData.append('file', arq);
    axios.post(`/ftp/arquivos/${caminho.replace('%2F', '/')}`, formData, {
      headers: {
        'Authorization': token
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    })
      .then(response => {
        setStatusBar(false);
        toggleMensagem(`Arquivo enviado com sucesso!`, 'success');
        setLoading(false);
      })
      .catch(error => {
        toggleMensagem(error, 'error');
        setLoading(false);
      });
  }


  const deleteArqFTP = async (nome) => {
    fetch(`https://server.redsis.com.br:8085/ftp/arquivos${nome.replace('%2F', '/')}`, {
      method: 'DELETE',
      headers: {
        'Authorization': token
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao executar comando.');
        }
        toggleMensagem(`Arquivo excluída com sucesso!`, 'success');
        setLoading(false);
      })
      .catch(error => {
        toggleMensagem('Erro ao excluir Arquivo.', 'error');
        setLoading(false);
      });
  }


  const postDirFTP = async (nome) => {
    fetch(`https://server.redsis.com.br:8085/ftp/diretorios${nome.replace('%2F', '/')}`, {
      method: 'POST',
      headers: {
        'Authorization': token
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao executar comando.');
        }
        toggleMensagem(`Pasta criada com sucesso!`, 'success');
        setLoading(false);
      })
      .catch(error => {
        toggleMensagem('Erro ao criar nova pasta.', 'error');
        setLoading(false);
      });
  };


  const deleteDirFTP = async (nome) => {
    fetch(`https://server.redsis.com.br:8085/ftp/diretorios${nome.replace('%2F', '/')}`, {
      method: 'DELETE',
      headers: {
        'Authorization': token
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao executar comando.');
        }
        toggleMensagem(`Pasta excluída com sucesso!`, 'success');
        setLoading(false);
      })
      .catch(error => {
        toggleMensagem('Erro ao excluir pasta.', 'error');
        setLoading(false);
      });
  };

  // const deleteDirFTP = async (nome) => {
  //   try {
  //     setStatusBar(true);
  //     await axios.delete(`/ftp/diretorios/${nome}`, config);
  //     setProgress(0)
  //     setStatusBar(false);

  //   } catch (error) {
  //     console.log(error)
  //     setProgress(0)
  //     setStatusBar(false);

  //   }
  // }

  return { getFTP, postArqFTP, deleteArqFTP, arquivos, progress, setProgress, statusBar, loading, postDirFTP, deleteDirFTP }
}
export default useFTP;