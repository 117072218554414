import React, { useEffect, useState } from 'react';
import { ContainerPages } from '../../components/container';
import useFTP from '../../hooks/useFTP';
import { Box, Breadcrumbs, Divider, Input, LinearProgress, List, ListItem, Paper } from '@mui/material';
import { StackJustify, StackLeft } from '../../components/stack';
import { ButtonToolTip } from '../../components/button';
import { TitleSmall, FileFolderName, TitleGrupos } from '../../components/typography';
import { FileIcon, Folder, NewFolder, DeleteFolder, Storage, ReloadIcon, CloseIcon, UploadIcon, DownloadIcon, ArrowKeyLeft } from '../../components/icons';
import { DialogFTP } from '../../components/dialog';
import { TextFieldDefault } from '../../components/textfield';

function FTP() {
    const { getFTP, postDirFTP, deleteDirFTP, deleteArqFTP, arquivos, loading, progress, statusBar, postArqFTP } = useFTP();
    const [caminho, setCaminho] = useState('');
    const [select, setSelect] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState('%2FArquivos');
    const [listPath, setListPath] = useState(['ARQUIVOS']);
    const [openDialogNew, setOpenDialogNew] = useState(false);
    const [openDialogDel, setOpenDialogDel] = useState(false);
    const [nome, setNome] = useState('');
    const [at, setAt] = useState(false);
    const [selectedFile, setSelectedFile] = useState(undefined);

    useEffect(() => {
        getFTP(caminho);
        setBreadcrumbs(breadcrumbs + caminho);
        setNome('');
        setAt(false);
        setSelectedFile(undefined);
    }, [loading, caminho, openDialogNew === false, openDialogDel === false, at === true])

    const setPath = (path) => {
        let localPath = caminho.includes('%7Bcaminho%7D') ? caminho.replace('ftp/arquivos/%7Bcaminho%7D', `/ftp/arquivos/${path}`) : caminho + '%2F' + path;
        setListPath([...listPath, path]);
        setCaminho(localPath.replaceAll('/%2F', '/'));
    }

    function toGo(index) {
        let str = '';
        for (let i = 1; i <= index; i++) {
            str = str + '%2F' + listPath[i]
        }
        setCaminho(str);
        let lista = ['ARQUIVOS', ...str.split('%2F')];
        let novaLista = [];
        for (let i = 0; i < lista.length; i++) {
            if (lista[i] !== '')
                novaLista.push(lista[i])
        }
        setListPath([...novaLista]);
    }

    function ListaCaminho(item, i) {
        return <span onClick={() => toGo(i)} ><TitleSmall title={item} /></span>
    }

    function novoDiretorio(nome) {
        postDirFTP(caminho + '%2F' + nome);
        setOpenDialogNew(false);
        setNome('');
    }

    function apagarDiretorio() {
        deleteDirFTP(caminho + '%2F' + select)
        setOpenDialogDel(false);
    }

    function apagarArquivo() {
        deleteArqFTP(caminho + '%2F' + select)
        setOpenDialogDel(false);
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (selectedFile) {
            postArqFTP(caminho, selectedFile)
        }
        setSelectedFile(undefined);
        setAt(!loading);
    };

    function ListarInfo(props) {
        let arquivo = props.arquivo;
        let tipo = props.tipo
        return (
            <ListItem onClick={() => setSelect(arquivo.nome)} onDoubleClick={() => setPath(arquivo.nome)} style={{ cursor: 'pointer', margin: '5px 0', maxWidth: '50px' }}>
                <Paper elevation={select === arquivo.nome ? 2 : 0} style={{ background: select === arquivo.nome ? '#ed3237' : 'inherit' }}>
                    <Box p={select === arquivo.nome ? 1 : 0}>
                        <StackLeft>
                            {tipo === 'A' ? <FileIcon color='blue' /> : <Folder color='orange' />}
                            {tipo === 'A' && select === arquivo.nome ? '' : <FileFolderName title={arquivo.nome} color={select === arquivo.nome ? 'white' : 'inherit'} />}
                            {tipo === 'A' && select === arquivo.nome ?
                                <>
                                    {/* <ButtonToolTip icon={<DownloadIcon color='blue' />} title='Download' onClick={() => setAt(true)} /> */}
                                    {/* <ButtonToolTip icon={<DownloadIcon color='blue' />} title='Download' 
                                    onClick={
                                        () => setAt(true)
                                    } /> */}

                                    <a href={`https://server.redsis.com.br:8085/ftp/arquivos${caminho}/${arquivo.nome}`} target="_blank" style={{ textDecoration: 'none' }}><TitleSmall title={arquivo.nome} /></a>

                                    <ButtonToolTip icon={<CloseIcon color='white' />} title='Excluir Arquivo' onClick={() => apagarArquivo()} />
                                </> :
                                <></>}
                        </StackLeft>
                    </Box>
                </Paper>
            </ListItem>
        );
    }

    function ListArquivos(arquivo, a) {
        return <ListarInfo arquivo={arquivo} key={a} tipo='A' />
    }

    function ListPastas(arquivo, a) {
        return <ListarInfo arquivo={arquivo} key={a} tipo='P' />
    }

    return (
        <ContainerPages
            activeAuxBar={true}
            ativarLogo={true}
            overflow={false}
            components={
                <Paper elevation={0}>
                    <StackLeft>
                        <Storage color='#ed3237' />
                        <Breadcrumbs aria-label="breadcrumb" style={{ cursor: 'pointer', userSelect: 'none' }}>
                            {listPath.map(ListaCaminho)}
                        </Breadcrumbs>
                    </StackLeft>
                </Paper>
            }
            btnAux={<>
                <ButtonToolTip icon={<NewFolder fontSize='28px' color='green' />} title='Nova Pasta' onClick={() => setOpenDialogNew(true)} />
                <ButtonToolTip icon={<DeleteFolder fontSize='28px' color='red' />} title='Excluir Pasta' onClick={() => setOpenDialogDel(true)} />
                <StackJustify>
                    <StackLeft>
                        <Input
                            type="file"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            id="upload-file"
                        />
                        <label htmlFor="upload-file">
                            <ButtonToolTip
                                icon={<UploadIcon fontSize='28px' />}
                                color='blue'
                                title='Selecione o arquivo'
                                component="span"
                            />
                        </label>
                    </StackLeft>
                </StackJustify>
                <ButtonToolTip icon={<ReloadIcon fontSize='28px' />} title='Recarregar' onClick={() => setAt(true)} />
            </>}
        >
            <List style={{ marginTop: '-10px' }}>
                {!loading ?
                    <>
                        {listPath.length > 1 ?
                            <ListItem onClick={() => toGo(listPath.length - 2)} style={{ cursor: 'pointer', margin: '5px 0', maxWidth: '50px', color: 'orange' }}>
                                <StackLeft>
                                    <ArrowKeyLeft color='orange' />
                                    <FileFolderName title='Voltar' />
                                </StackLeft>
                            </ListItem> : ''}
                        {arquivos.arquivos.map(ListArquivos)}
                        {arquivos.pastas.map(ListPastas)}
                    </>
                    :
                    <TitleSmall title='Não existem arquivos nesta pasta.' />
                }
            </List>
            <DialogFTP open={openDialogNew} onClose={() => setOpenDialogNew(false)} title='Nova Pasta' onClick={() => novoDiretorio(nome)}>
                <TextFieldDefault id='nome' autoFocus={true} name='nome' label='Nome da Pasta' onChange={(e) => setNome(e.target.value)} value={nome} />
            </DialogFTP>
            <DialogFTP open={openDialogDel} onClose={() => setOpenDialogDel(false)} title={`Deseja excluir ${select}`} onClick={() => apagarDiretorio()} />
            <>{
                statusBar && progress < 100 ? <><LinearProgress variant="determinate" value={progress} /><div style={{ textAlign: 'center' }}><TitleGrupos title={`${progress} %`} /></div></> :
                    <Box pl={2}>
                        {selectedFile && (
                            <>
                                <Divider textAlign="left" style={{ margin: '10px 0px 10px 0px' }}><TitleGrupos title="Arquivo pendende de envio" /></Divider>
                                <StackLeft>
                                    <TitleGrupos title="Arquivo:" />
                                    {selectedFile ? <TitleSmall title={selectedFile.name} /> : ''}<br />
                                    <Box>
                                        <ButtonToolTip
                                            icon={<UploadIcon />}
                                            color='blue'
                                            title='Enviar'
                                            onClick={handleUpload}
                                        />
                                        <ButtonToolTip
                                            icon={<CloseIcon />}
                                            color='red'
                                            title='Cancelar'
                                            onClick={() => setSelectedFile(undefined)}
                                        />
                                    </Box>
                                </StackLeft>
                            </>
                        )}
                    </Box>
            }
            </>
        </ContainerPages>
    );
}

export default FTP;