import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../pages/login';
import NotFound from '../pages/notfound';
import Sac from '../pages/sac';
import Versionamento from '../pages/version';
import Clientes from '../pages/clientes';
import SacFinalizados from '../pages/finalizado';
import Rastreios from '../pages/rastreios';
import Marcadores from '../pages/marcadores';
import Setores from '../pages/setores';
import CadMensagem from '../pages/cadmensagem';
import FAQ from '../pages/faq';
import Form from '../pages/form';
import FormExterno from '../pages/externo';
import Teste from '../pages/teste';
import { getUser } from '../utils/user';
import MudarTags from '../pages/mudartags';
import SharePage from '../pages/sharepage';
import LoginComp from '../pages/logincomp';
// import TestComplete from '../pages/testcomplete';
import Movimentos from '../pages/movimentos';
import ListFaq from '../pages/listfaq';
import FAQConfig from '../pages/configfaq';
import ListFaqPend from '../pages/listfaqpend';
import FTP from '../pages/ftp';

function Rotas() {
  return (
    !getUser() ?
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/sac/externo/create/:cnpjcpf" exact element={<FormExterno />} />
        <Route path="/sac/externo/create" exact element={<FormExterno />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      :
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/sac/externo/create/:cnpjcpf" exact element={<FormExterno />} />
        <Route path="/sac/externo/create" exact element={<FormExterno />} />
        <Route path="/sac" exact element={<Sac />} />
        <Route path="/versao" exact element={<Versionamento />} />
        <Route path="/clientes" exact element={<Clientes />} />
        <Route path="/finalizados" exact element={<SacFinalizados />} />
        <Route path="/rastreios" exact element={<Rastreios />} />
        <Route path="/marcadores" exact element={<Marcadores />} />
        <Route path="/movimentos" exact element={<Movimentos />} />
        <Route path="/setores" exact element={<Setores />} />
        <Route path="/mensagem" exact element={<CadMensagem />} />
        <Route path="/mudartags" exact element={<MudarTags />} />
        <Route path="/ftp" exact element={<FTP />} />
        <Route path="/faq" exact element={<ListFaq />} />
        <Route path="/faq/:codigo" exact element={<ListFaq />} />
        <Route path="/faq/create" exact element={<FAQ />} />
        <Route path="/faq/pend" exact element={<ListFaqPend />} />
        <Route path="/faq/config" exact element={<FAQConfig />} />
        <Route path="/faq/edit/:codigo" exact element={<FAQ />} />
        <Route path="/sac/create" exact element={<Form />} />
        <Route path="/sac/edit/:codigo" exact element={<Form />} />
        <Route path="/teste" exact element={<Teste />} />
        <Route path="/comp" exact element={<LoginComp />} />
        <Route path="/comp/:id" exact element={<LoginComp />} />
        <Route path="/share/:id" exact element={<SharePage />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
  );
}

export default Rotas;
